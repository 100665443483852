<template>
  <component
    :is="tag"
    ref="container"
    class="animated-services"
    :style="{ '--n': blok.items.length, '--p': progress }"
  >
    <div ref="sticky" class="sticky-content section">
      <div class="medias-container">
        <template v-for="(media, index) in medias">
          <StoryblokComponent
            v-if="media.length === 1"
            :key="media[0]._uid"
            :blok="media[0]"
            class="media"
            :style="{ '--i': index }"
            :size-hint="70"
          />
          <div
            v-if="media.length > 1"
            :key="media[0]._uid"
            class="media"
            :style="{ '--i': index }"
          >
            <GenericCarousel :items="media" />
          </div>
        </template>
      </div>
    </div>

    <div class="scrolling-content section-full">
      <div
        v-for="component in blok.items"
        :key="component._uid"
        :class="['service-container', 'section', {'is-scroll-snapped': progress != 0 && progress!= 1 }]"
        :style="{ '--background': component.background_color }"
      >
        <StoryblokComponent
          :blok="component"
          :class="['service', 'section-inner', {'solid-03': component.background_color === 'var(--solid-03)'}]"
          :level="2"
          title-size="size-xl"
          text-size="size-s"
          button-margin-top="margin-s-top"
          :size-hint="70"
          @dynamic-slider-tracking="tracking"
        />
      </div>
    </div>
  </component>
</template>

<script setup>
import { useTrackingStore } from "@/stores/tracking";

const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
  tag: {
    type: String,
    required: false,
    default: "div",
  },
});
const trackingStore = useTrackingStore();

const { blok } = toRefs(props);

const container = ref(null);
const sticky = ref(null);

const isDesktop = inject("isDesktop");

const progress =
  blok.value.items?.length < 2
    ? ref(0)
    : useStickyScrollProgress(container, sticky, isDesktop);

const currentIndex = computed(() => {
  return Math.min(
    blok.value.items?.length - 1,
    Math.floor(progress.value * blok.value.items?.length)
  );
});

const medias = computed(() => {
  return blok.value.items?.map((item) => item.media);
});

const currentBackground = computed(() => {
  return blok.value.items?.map((item) => item.background_color)[
    currentIndex.value
  ];
});

const tracking = (data) => {
  trackingStore.sendTrackingData({
    event: "click.navigation",
    click: useSnakeCaseFilter(data.cta_label),
    click_chapter1: "dynamic_slider",
    click_chapter2: "CTA",
    click_chapter3: useSnakeCaseFilter(getTextFromRichText(data.title)),
  });
};
</script>

<style lang="scss" scoped>
.background-animated-services {
  background-color: (v-bind(currentBackground));
  transition: background-color 0.8s;
}
.animated-services {
  @include dynamic-pair(v-bind(currentBackground));
  transition: background-color 0.8s;

  &.section {
    padding: 0;
  }

  .sticky-content {
    position: sticky;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    pointer-events: none;

    @include for-desktop-down {
      display: none;
    }
  }

  .scrolling-content {
    @include for-desktop-up {
      margin-top: -100vh;
    }
  }

  .medias-container {
    grid-column: 11/-2;
    aspect-ratio: 1;
    max-height: 85vh;
    width: 100%;
    overflow: hidden;
    align-self: center;
    border-radius: $radius-l;
    pointer-events: auto;
  }

  .media {
    position: absolute;
    display: flex;
    border-radius: $radius-l;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: calc(var(--n) - var(--i));
    $a: calc(var(--i) / (var(--n) - 1));
    $b: calc((var(--i) + 1) / (var(--n) - 1));
    transform: translateY(calc(-100% * map-interval(var(--p), $a, $b)));

    :deep(img) {
      width: 100%;
      height: 100%;
    }

    :deep(.swiper-slide > *) {
      height: 100%;
    }
  }
  .service-container {
    background: var(--background);

    @include for-desktop-up {
      background: none;
      height: 100vh;
    }

    &.is-scroll-snapped {
      @include for-desktop-up {
        scroll-snap-align: start;
      }
    }

    &.section {
      @include for-desktop-down {
        padding-top: 1.125rem;
      }
      @include for-desktop-up {
        max-width: 100%;
      }
    }
  }

  .service {
    @include for-desktop-up {
      grid-column: 2/10;
    }
  }

  .media-text-cta {
    border-radius: 0;
    background: none;
    padding: 0;
    gap: 2.5rem;

    :deep(.simple-button) {
      background-color: var(--background);

      @include for-desktop-up {
        background-color: v-bind(currentBackground);
        transition: background-color 0.8s;
      }
    }

    :deep(.text-wrapper) {
      max-width: 30rem;
      padding-left: 0rem;
      padding-right: 0rem;
    }

    @include for-desktop-up {
      align-self: center;

      :deep(.media),
      :deep(.media-carousel) {
        display: none;
      }

      :deep(.title) {
        margin-bottom: 0.75rem;
      }
    }
  }
}
</style>
